import { InjectableConfig } from '../enums';
import { WindowedObservable } from '../windowed-observable';
export var getAppBuildData = function() {
    var buildDataObservable = new WindowedObservable(InjectableConfig.BuildMap);
    var _buildDataObservable_getLastEvent;
    return (_buildDataObservable_getLastEvent = buildDataObservable.getLastEvent()) !== null && _buildDataObservable_getLastEvent !== void 0 ? _buildDataObservable_getLastEvent : {};
};
export var updateAppBuildData = function(buildApp, buildId) {
    var buildDataObservable = new WindowedObservable(InjectableConfig.BuildMap);
    var _buildDataObservable_getLastEvent;
    var buildData = (_buildDataObservable_getLastEvent = buildDataObservable.getLastEvent()) !== null && _buildDataObservable_getLastEvent !== void 0 ? _buildDataObservable_getLastEvent : {};
    buildData[buildApp] = buildId;
    buildDataObservable.publish(buildData);
};

module.exports = new Promise((resolve, reject) => {
      const remoteScope = 'vendorMfe'
      const remoteURLs = JSON.parse('{"vendorMfe":{"local":"http://127.0.0.1:8090","dev":"https://gw-dev-vendor-mfe-web-a3cea9h2b4gch9eq.canadacentral-01.azurewebsites.net","qa":"https://gw-qa-vendor-mfe-web-gjgched6hubff6c7.canadacentral-01.azurewebsites.net","uat":"https://gw-uat-vendor-mfe-web-efc7dyargsa9bcdr.canadacentral-01.azurewebsites.net","prod":"https://vendor-mfe-portal.gorillaworks.io"},"onboardingMfe":{"local":"http://127.0.0.1:8091","dev":"https://portal-dev-onboarding-web-djc8e3dpcdg0djfy.canadacentral-01.azurewebsites.net","qa":"https://portal-qa-onboarding-web-araabhgcdffmaedh.canadacentral-01.azurewebsites.net","uat":"https://portal-uat-onboarding-web-ddatchg5hjdxe0h7.canadacentral-01.azurewebsites.net","prod":"https://onboarding-mfe-portal.gorillaworks.io","parentScope":"vendorMfe"}}')
      const isDevelopmentEnv = false
      const remoteEnv = ''
      const disableParentScope = false
      
      const getRemoteURL = () => {
        if (window.location.hostname.includes('local') || window.location.hostname.includes('127.0.0.1'))
          return remoteURLs[remoteScope].local

        if (window.location.hostname.includes('-qa')) {
          return remoteURLs[remoteScope].qa
        }
        
        if (window.location.hostname.includes('-dev')) {
          return remoteURLs[remoteScope].dev
        }
        
        if (window.location.hostname.includes('-uat')) {
          return remoteURLs[remoteScope].uat
        }
        
        return remoteURLs[remoteScope].prod
      }
  
      
      const initRemoteEntryScript = (url, onError = null) => {
        const script = document.createElement('script')
        script.src = url
        script.onload = () => {
          // the injected script has loaded and is available on window
          // we can now resolve this Promise
          const proxy = {
            get: (request) => window['vendorMfe'].get(request),
            init: (...arg) => {
              try {
                return window['vendorMfe'].init(...arg)
              } catch(e) {
                console.log('remote container already initialized')
              }
            }
          }
          const parentScope = remoteURLs[remoteScope].parentScope
          
          if(parentScope && !disableParentScope){
            let intervalId = setInterval(() => {
              if(window[parentScope]){
                resolve(proxy)
                clearInterval(intervalId);
              }
            }, 10)
          } else {
            resolve(proxy)
          }
        }
        script.onerror = () => {
          if(onError){
            onError()
          } else {
            reject(new Error(`[vendorMfe] error loading remote`))
          }
        }
        // inject this script with the src set to the versioned remoteEntry.js
        document.head.appendChild(script);
      }
      
      window.remoteMfeMap = {
        ...(window.remoteMfeMap ?? {}),
        [remoteScope]: getRemoteURL()
      }
      
      
      if (!window[remoteScope]) {
        const remoteUrlFallback = remoteURLs[remoteScope].dev + '/remoteEntry.js'
        const remoteUrl = remoteEnv ? remoteURLs[remoteScope][remoteEnv] : getRemoteURL()

        const alphaUrl = remoteUrl + '/remoteEntry.js'
              
        if(isDevelopmentEnv && !remoteEnv) {
          initRemoteEntryScript(alphaUrl, () => initRemoteEntryScript(remoteUrlFallback))
        } else {
          initRemoteEntryScript(alphaUrl)
        }
      } else {
        resolve(window[remoteScope])
      }
    })
    ;
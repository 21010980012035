import {
  AppTypes,
  InjectableConfig,
  updateAppBuildData,
  WindowedObservable,
} from '@procom-labs/common'

new WindowedObservable(InjectableConfig.AppType).publish(AppTypes.submission)
updateAppBuildData(
  AppTypes.submission,
  process.env.REACT_APP_BUILD_NUMBER ?? null
)

import(/* webpackChunkName: "boot-loader" */ './boot-loader')
export {}
